<template>
  <div class="page p-p-3">
    <Breadcrumb :model="items"/>
    <DataTable :value="products" autoLayout="true" class="p-mt-5">
      <template #header>
        <div class="p-d-flex" style="text-align: left">
          <InputText class="" placeholder="姓名" type="text"/>
          <Button class="p-button-sm p-ml-3" label="查询"/>
          <Dropdown v-model="selectedCity" :options="cities" class="p-ml-5" optionLabel="name" placeholder="年龄"/>
          <InputText class="p-ml-5" placeholder="证件号码" type="text"/>
        </div>
      </template>
      <Column field="id" header="序号"></Column>
      <Column field="name" header="姓名"></Column>
      <Column field="idCard" header="证件号码"></Column>
      <Column field="phone" header="电话"></Column>
      <Column field="age" header="年龄"></Column>
      <Column field="time" header="违约时间" style=""></Column>
      <Column header="操作">
        <template #body class="p-d-flex">
          <Button class="p-button-text" label="查看详情" @click="$router.push('/recruit/blackListDetail')"/>
          <Button class="p-button-text" label="解除黑名单"/>
        </template>
      </Column>
    </DataTable>
    <Paginator :rows="10" :totalRecords="totalItemsCount"></Paginator>
  </div>
</template>
<script>
export default {
  name: "blacklist",
  data() {
    return {
      items: [
        {label: '招聘管理', to: ''},
        {label: '招聘黑名单列表',}
      ],
      products: [
        {
          'id': '123444',
          'name': '狗蛋',
          'idCard': '本科',
          'phone': '10000',
          'age': '招聘中',
          'time': '2012-10-2 15:26:09'
        },

      ],
    }
  }
}
</script>

<style scoped>

</style>